import styled, { css } from "styled-components"
import Button from "./Button"

export const styles = css`
  background-color: white;
  color: black;
  padding: 0.75rem 2rem;
  line-height: 1.1875rem;
  letter-spacing: -0.0225rem;
`

const SecondaryButton = styled(Button)`
  ${styles}
`

export default SecondaryButton
