import React from "react"
import styled from "styled-components"

import { Container as SectionContainer } from "../../components/Section"
import Testimonials from "../../types/api/Testimonials"
import Body, { BaseContainer } from "../../components/Section/Body"
import List from "./List"
import Title from "./Title"
import { getPaddingY } from "../../utils/styles"
import { breakpoints } from "../../constants/breakpoints"

interface Props extends Testimonials {}

const Container = styled(SectionContainer)`
  text-align: center;
`

const BodyContainer = styled(BaseContainer)`
  ${getPaddingY("3rem")}

  ${breakpoints("md")`
    ${getPaddingY("5rem")}
  `}
`

export default function TestimonialsSection({ title, content, logos }: Props) {
  return (
    <Container background="secondary">
      <Body
        title={title}
        content={content}
        Title={Title}
        Container={BodyContainer}
      >
        <List logos={logos} />
      </Body>
    </Container>
  )
}
