import React from "react"
import styled from "styled-components"
import { breakpoints } from "../../../constants/breakpoints"

import Thumbnail from "../../../types/api/Thumbnail"
import ListItem from "./ListItem"
import { flexBreakpoint } from "./styles"

interface Props {
  logos: Thumbnail[]
}

const Container = styled.ul`
  opacity: 0.5;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;

  ${flexBreakpoint`
    margin-top: 3.3125rem;
    flex-direction: row;
    justify-content: center;
  `}

  ${breakpoints("xl")`
    margin-top: 2.75rem;
  `}
`

export default function List({ logos }: Props) {
  const renderLogo = (logo: Thumbnail, index: number): JSX.Element => {
    return <ListItem {...logo} key={index} />
  }

  const renderedLogos: JSX.Element[] = logos.map(renderLogo)

  return <Container>{renderedLogos}</Container>
}
