import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import { theme } from "../constants/theme"
import CallToActionType from "../types/api/CallToAction"
import { Heading as BaseHeading } from "./styles"
import { breakpoints } from "../constants/breakpoints"
import { getPaddingY } from "../utils/styles"
import { Container as BaseContainer } from "./Section"
import { sectionIds } from "../constants/sections"
import { SecondaryGatsbyLink } from "./SecondaryLink"
import { getAnchorLink } from "../utils/links"

const textColor: string = "white"

const Container = styled(BaseContainer)`
  color: ${textColor};
  background-color: #353535;
  text-align: center;
  padding: 4rem ${theme.pagePaddingX};

  ${breakpoints("md")`
    ${getPaddingY("4.625rem")}
  `}
`

const Body = styled.div`
  margin-bottom: 2rem;

  ${breakpoints("md")`
    margin-bottom: 2.5rem;
  `}
`

const Heading = styled(BaseHeading)`
  color: ${textColor};
  &::after {
    display: none;
  }
`

const Text = styled.p`
  color: ${textColor};
`

interface Query {
  strapiCallToAction: CallToActionType
}

export default function CallToAction() {
  const {
    strapiCallToAction: { title, description, buttonText },
  }: Query = useStaticQuery(graphql`
    query CallToAction {
      strapiCallToAction {
        title
        description
        buttonText
      }
    }
  `)

  const contactLink: string = getAnchorLink(sectionIds.contact)

  return (
    <Container>
      <Body>
        <Heading>{title}</Heading>
        <Text>{description}</Text>
      </Body>
      <SecondaryGatsbyLink to={contactLink}>{buttonText}</SecondaryGatsbyLink>
    </Container>
  )
}
