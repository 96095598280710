import React from "react"

import Section from "../components/Section"
import Home from "../types/api/Home"

interface Props {
  beliefs: Home["beliefs"]
}

export default function Beliefs({ beliefs }: Props) {
  return <Section {...beliefs} imageDirection="right" background="secondary" />
}
