import React from "react"
import styled from "styled-components"

import { breakpoints } from "../../../constants/breakpoints"
import Thumbnail from "../../../types/api/Thumbnail"
import { flexBreakpoint } from "./styles"

const Container = styled.li`
  list-style: none;
  max-width: 7.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  ${flexBreakpoint`
    max-width: 8.125rem;
  `}

  ${breakpoints("xl")`
    max-width: 10rem;
  `}
  
  & + & {
    margin-top: 1.875rem;

    ${flexBreakpoint`
      margin-top: 0;
      margin-left: 2.5rem;
    `}

    ${breakpoints("xl")`
      margin-left: 3.875rem;
    `}
  }
`

const Image = styled.img`
  width: 100%;
`

interface Props extends Thumbnail {}

export default function ListItem({ localFile, alternativeText }: Props) {
  return (
    <Container>
      <Image src={localFile.publicURL} alt={alternativeText} />
    </Container>
  )
}
