import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import Team from "../components/Team"
import Home from "../types/api/Home"
import Introduction from "./Introduction"
import Beliefs from "./Beliefs"
import Specialties from "./Specialties"
import Testimonials from "./Testimonials"
import CallToAction from "../components/CallToAction"

interface Query {
  strapiHome: Home
}

interface Props {
  data: Query
}

const IndexPage = ({
  data: {
    strapiHome: { introduction, beliefs, specialties, testimonials },
  },
}: Props) => (
  <Layout>
    <Seo title="Home" />
    <Hero title="Digitalisation done right." image="helixbridge" />
    <Introduction introduction={introduction} />
    <Beliefs beliefs={beliefs} />
    <Specialties specialties={specialties} />
    <Testimonials {...testimonials} />
    <CallToAction />
    <Team />
  </Layout>
)

export default IndexPage
