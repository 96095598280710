import React from "react"

import Section from "../components/Section"
import Home from "../types/api/Home"

interface Props {
  introduction: Home["introduction"]
}

export default function Introduction({ introduction }: Props) {
  return <Section {...introduction} imageDirection="left" />
}
