import React from "react"

import Section from "../components/Section"
import Home from "../types/api/Home"

interface Props {
  specialties: Home["specialties"]
}

export default function Specialties({ specialties }: Props) {
  return <Section {...specialties} imageDirection="left" iconsOnXl={3} />
}
