import styled from "styled-components"

import Link, { StyledGatsbyLink } from "./Link"
import { styles as baseStyles } from "./SecondaryButton"

const SecondaryLink = styled(Link)`
  ${baseStyles}
`

export const SecondaryGatsbyLink = styled(StyledGatsbyLink)`
  ${baseStyles}
`

export default SecondaryLink
